import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";

const pathsToHideNavigation = ["/pricing", "/oauth_logging_in"];
const pathsToHideFooter = ["/oauth_logging_in"];
const isModelRoute = (pathname) => /^\/models(\/new|\/[^/]+(\/upload)?)$/.test(pathname);

export const useShouldHideNavigation = () => {
  const pathname = usePathname();
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const response = pathsToHideNavigation.some((path) => pathname.includes(path)) || isModelRoute(pathname);
    setShouldHide(response);
  }, [pathname]);

  return shouldHide;
};

export const useShouldHideFooter = () => {
  const pathname = usePathname();
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const response = pathsToHideFooter.some((path) => pathname.includes(path)) || isModelRoute(pathname);
    setShouldHide(response);
  }, [pathname]);

  return shouldHide;
};
